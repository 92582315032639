<template>
  <div class="container">
    <h4 class="container__title">
      {{ title }}
    </h4>
    <h5
      v-if="title !== defaultTitle"
      class="container__subtitle"
    >
      {{ `(${defaultTitle})` }}
    </h5>
    <ReflectTag
      class="container__tag"
      :text="$t(sourceTypeTranslationKeyMap[sourceType])"
      :theme="sourceTypeThemeMap[sourceType]"
    />
  </div>
</template>

<script lang="ts">
import {
  sourceTypeThemeMap,
  sourceTypeTranslationKeyMap
} from '~/constants/sources'
import type { SourceType } from '~/types/sources'

export default {
  name: 'ModuleInfoTitle',
  props: {
    sourceType: {
      type: String as PropType<SourceType>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    defaultTitle: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      sourceTypeTranslationKeyMap,
      sourceTypeThemeMap
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: baseline;
  margin-bottom: $margin-intermediate;

  &__title {
    @include font-text($font-weight-medium);
    @include font-size($font-size-normal);
    color: $text-secondary;
  }

  &__subtitle {
    @include font-text($font-weight-book);
    @include font-size($font-size-regular);
    color: $text-tertiary;
    margin-left: $margin-tiny;
  }

  &__tag {
    align-self: center;
    margin-left: $margin-small;
  }
}
</style>
